import UserModal from '@/modal/userModal'
import useUser from '@/views/user/useUser'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import axiosIns from '@/libs/axios'
import { getUserData } from '@/auth/utils'
import util from '@/global/util'

export default function useUserDetail(props) {
  const {
    pageMode,
    fetchUser,
    updateUser,
    deleteUser,
  } = useUser()

  const user = UserModal()

  const dataReportProduct = ref(null)
  const dataReportCustomer = ref(null)
  const chartOptions = ref(null)
  const today = new Date().toISOString()
  const files = ref([])
  const userData = getUserData()
  const userReportData = ref({})
  const userChartData = ref({})
  const keyRefreshComponent = ref(1000)
  const isReportMe = ref(false)

  const productChartTownhouse = ref({
    series: [{
      name: 'Biểu đồ sản phẩm',
      data: [],
    }],
    options: {
      markers: {
        size: 4,
      },
      yaxis: {
        labels: {
          formatter: val => util.formatNumber(val),
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => util.formatNumber(val),
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: true,
          minHeight: 40,
          // hideOverlappingLabels: false,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        height: 350,
        zoom: {
          enabled: false,
        },
        id: 'dashboard-chart-product',
      },
    },
  })
  const productChartOffice = ref({
    series: [{
      name: 'Biểu đồ sản phẩm',
      data: [],
    }],
    options: {
      markers: {
        size: 4,
      },
      yaxis: {
        labels: {
          formatter: val => util.formatNumber(val),
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => util.formatNumber(val),
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: true,
          minHeight: 40,
          // hideOverlappingLabels: false,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        height: 350,
        zoom: {
          enabled: false,
        },
        id: 'dashboard-chart-product',
      },
    },
  })
  const customerChartTownhouse = ref({
    series: [{
      name: 'Biểu đồ khách hàng',
      data: [],
    }],
    options: {
      markers: {
        size: 4,
      },
      yaxis: {
        labels: {
          formatter: val => util.formatNumber(val),
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => util.formatNumber(val),
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: true,
          minHeight: 40,
          // hideOverlappingLabels: false,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        height: 350,
        zoom: {
          enabled: false,
        },
        id: 'dashboard-chart-customer',
      },
    },
  })
  const customerChartOffice = ref({
    series: [{
      name: 'Biểu đồ khách hàng',
      data: [],
    }],
    options: {
      markers: {
        size: 4,
      },
      yaxis: {
        labels: {
          formatter: val => util.formatNumber(val),
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => util.formatNumber(val),
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: true,
          minHeight: 40,
          // hideOverlappingLabels: false,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        height: 350,
        zoom: {
          enabled: false,
        },
        id: 'dashboard-chart-customer',
      },
    },
  })

  const m = new Date().getMonth() + 1
  const y = new Date().getFullYear()
  const monthFilter = ref(null)
  const yearFilter = ref(y)

  const getDateRange = () => {
    if (!yearFilter.value) {
      return {
        from: '',
        to: '',
      }
    }

    return {
      from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
        : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
      to: !monthFilter.value ? new Date(yearFilter.value, 12, 0) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
    }
  }

  const tableColumns = ref([
    {
      label: 'Ngày tháng',
      key: 'ngay-thang',
    },
    {
      label: 'Hành động',
      key: 'hanh-dong',
    },
    {
      label: 'Đối tượng',
      key: 'doi-tuong',
    },
    {
      label: 'Thiết bị',
      key: 'thiet-bi',
    },
  ])
  const filterDateRange = ref([])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const activityList = ref([])
  const totalActivity = ref(0)

  const getQuery = filter => {
    const {
      from,
      to,
    } = getDateRange()

    return http.buildQuery({
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
      user_id: filter.user_id || user.id,
    }) || ''
  }

  const buildQueryWithCustom = customFilter => http.buildQuery({
    ...({
      page: currentPage.value - 1,
      perpage: perPage.value,
      from: http.getISODate(filterDateRange.value[0]),
      to: http.getISODate(filterDateRange.value[1]),
      user_id: props.userId,
    }),
    ...customFilter,
  })

  const branches = computed(() => store.state.app.branches)

  const fetchReportUser = (filter = {}) => {
    const queryString = getQuery(filter)
    return http.handle(axiosIns.get(`/api.report/${isReportMe.value ? 'me' : 'user'}${queryString || ''}`), res => {
      userReportData.value = res.data.report || {}
      userChartData.value = res.data.chart || {}
    })
  }

  const { parseFieldsToNumber } = util

  const formatClientData = (clientData = {}) => {
    // những trường này sẽ được chuyển thành kiểu number
    const NumberField = [
      'limit_price',
      'limit_office_price',
      'limit_townhouse_hire_price_show_phone',
      'limit_townhouse_sell_price_show_phone',
      'limit_townhouse_hire_price_show_product',
      'limit_townhouse_sell_price_show_product',
    ]

    return parseFieldsToNumber(clientData, NumberField)
  }
  const updateUserLimit = data => axiosIns.put(`/api.user/${data.id}/limit`, formatClientData(data))

  watch(files, n => {
    if (!n && !n.length) return
    const last = n[n.length - 1] || {}
    user.avatar = last.origin || ''
  })

  watch(() => user.input_limit_price, n => {
    if (!n) return
    user.limit_price = n * 1000000
  })

  watch(() => user.is_access_townhouse, n => {
    if (!n) {
      user.is_limit_townhouse_price_show_phone = false
      user.is_limit_townhouse_price_show_product = false
    }
  })

  watch([yearFilter, monthFilter], () => {
    fetchReportUser()
  })

  const fetchActivity = customFilter => {
    const queryString = buildQueryWithCustom(customFilter)
    return http.handle(store.dispatch('user/fetchActivity', queryString), res => {
      activityList.value = res.data || []
      totalActivity.value = res.total
    })
  }
  watch([currentPage, perPage, filterDateRange], () => {
    console.log('watch', currentPage.value, perPage.value)
    fetchActivity()
  })
  return {
    pageMode,
    user,
    today,
    branches,
    files,
    userReportData,
    userChartData,
    dataReportProduct,
    dataReportCustomer,
    chartOptions,
    userData,
    monthFilter,
    yearFilter,
    keyRefreshComponent,
    productChartTownhouse,
    productChartOffice,
    customerChartTownhouse,
    customerChartOffice,
    isReportMe,
    fetchUser,
    updateUser,
    updateUserLimit,
    deleteUser,
    fetchReportUser,
    tableColumns,
    perPage,
    currentPage,
    fetchActivity,
    activityList,
    totalActivity,
    perPageOptions,
    filterDateRange,
  }
}
